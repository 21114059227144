<script setup lang="ts">
import { ref, Ref } from "vue";

const plans: Ref<any> = ref([
  {
    plan_id: 1,
    plan_title: "算法基础学习",
    plan_title_description:
      "如果你完全没有学习过算法，没有接触过算法，please come here",
    create_name: "Mogullzr",
    avatar:
      "http://mogullzr001.oss-cn-beijing.aliyuncs.com/2024/08/7e0f3ceda888461785e03ab837e3ae98.jpeg",
    plan_num: 0,
  },
  {
    plan_id: 1,
    plan_title: "算法基础学习",
    plan_title_description:
      "如果你完全没有学习过算法，没有接触过算法，please come here",
    create_name: "Mogullzr",
    avatar:
      "http://mogullzr001.oss-cn-beijing.aliyuncs.com/2024/08/7e0f3ceda888461785e03ab837e3ae98.jpeg",
    plan_num: 0,
  },
] as any);
</script>
<template>
  <div class="flex card card-compact bg-base-100 shadow-xl mx-52">
    <div class="font-bold mx-auto text-4xl pt-6">ByteOJ原创路线学习</div>
    <a
      class="mx-auto btn bg-blue-300 hover:bg-blue-500 active:bg-blue-600 text-white text-lg m-4"
      href="http://mogullzr001.oss-cn-beijing.aliyuncs.com/files/%E7%AE%97%E6%B3%95%E5%AD%A6%E4%B9%A0%E5%9F%BA%E6%9C%AC%E8%B7%AF%E7%BA%BF.xmind"
    >
      <span>
        <svg
          t="1723008241527"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
        >
          <path d="M0 0h1024v1024H0z" fill="#FFFFFF"></path>
          <path
            d="M153.6 553.159111c0-15.758222 9.045333-30.151111 23.267556-36.949333l317.44-151.893334a40.96 40.96 0 0 1 35.384888 0l317.44 151.893334c14.222222 6.826667 23.267556 21.191111 23.267556 36.977778V839.68a40.96 40.96 0 0 1-40.96 40.96H194.56a40.96 40.96 0 0 1-40.96-40.96V553.159111z"
            fill="#006FE8"
          ></path>
          <path
            d="M235.52 225.28a81.92 81.92 0 0 1 81.92-81.92h389.12a81.92 81.92 0 0 1 81.92 81.92v501.76a81.92 81.92 0 0 1-81.92 81.92H317.44a81.92 81.92 0 0 1-81.92-81.92V225.28z"
            fill="#67B0FF"
          ></path>
          <path
            d="M153.6 562.204444a20.48 20.48 0 0 1 30.435556-17.92l466.488888 259.612445c36.949333 20.536889 22.357333 76.743111-19.911111 76.743111H194.56a40.96 40.96 0 0 1-40.96-40.96v-277.475556z"
            fill="#0076F6"
          ></path>
          <path
            d="M870.4 562.204444a20.48 20.48 0 0 0-30.435556-17.92l-466.488888 259.612445c-36.949333 20.536889-22.357333 76.743111 19.911111 76.743111h436.053333a40.96 40.96 0 0 0 40.96-40.96v-277.475556z"
            fill="#0076F6"
          ></path>
          <path
            d="M512 619.52a32.256 32.256 0 1 0 0-64.512 32.256 32.256 0 0 0 0 64.512z"
            fill="#FFFFFF"
          ></path>
          <path
            d="M404.48 393.728c0-56.035556 50.915556-96.768 107.52-96.768 56.604444 0 107.52 40.732444 107.52 96.768 0 48.810667-38.656 86.016-86.016 94.776889v1.991111a21.504 21.504 0 1 1-43.008 0v-21.504c0-11.889778 9.614222-21.504 21.504-21.504 38.4 0 64.512-26.680889 64.512-53.76s-26.112-53.76-64.512-53.76c-38.4 0-64.512 26.680889-64.512 53.76v10.752a21.504 21.504 0 1 1-43.008 0v-10.752z"
            fill="#FFFFFF"
            p-id="6160"
          ></path>
        </svg>
      </span>
      <span>学习路线</span>
    </a>
    <div
      class="container hover:bg-gray-100"
      v-for="plan in plans"
      :key="plan.plan_id"
    >
      <div class="divider my-0 h-0"></div>
      <router-link
        class="flex flex-row pl-4 py-2"
        :to="'/study/' + plan.plan_id"
      >
        <div class="basis-7/12">
          <div class="flex">
            <div class="ml-4 flex w-full my-3">
              <div class="avatar p-0">
                <div class="w-20 rounded-full">
                  <img :src="plan.avatar" />
                </div>
              </div>
              <div class="ml-4">
                <router-link
                  class="link link-hover text-xl"
                  :to="'study/' + plan.plan_id"
                  style="color: rgba(2, 132, 199, 0.99)"
                  >{{ plan.plan_title }}</router-link
                >
                <div class="text-gray-400">
                  {{ plan.plan_title_description }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="basis-3/12 text-center m-auto">
          <div class="text-gray-400 font-bold">参加人数</div>
          <div>{{ plan.plan_num }}</div>
        </div>
        <div class="basis-2/12 text-center m-auto">
          <div class="text-gray-400 font-bold">创建人</div>
          <div class="font-bold text-red-500">{{ plan.create_name }}</div>
        </div>
      </router-link>
    </div>
    <div class="divider m-0 h-0"></div>
  </div>
</template>

<style scoped></style>
