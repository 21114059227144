import ace from "ace-builds";

import modeJsonUrl from "ace-builds/src-noconflict/mode-json?url";
ace.config.setModuleUrl("ace/mode/json", modeJsonUrl);

////
import modeC_cppUrl from "ace-builds/src-noconflict/mode-c_cpp?url";
ace.config.setModuleUrl("ace/mode/c_cpp", modeC_cppUrl);

import modeJavaUrl from "ace-builds/src-noconflict/mode-java?url";
ace.config.setModuleUrl("ace/mode/java", modeJavaUrl);

import modePythonUrl from "ace-builds/src-noconflict/mode-python?url";
ace.config.setModuleUrl("ace/mode/python", modePythonUrl);

////

import modeJavascriptUrl from "ace-builds/src-noconflict/mode-javascript?url";
ace.config.setModuleUrl("ace/mode/javascript", modeJavascriptUrl);

import modeHtmlUrl from "ace-builds/src-noconflict/mode-html?url";
ace.config.setModuleUrl("ace/mode/html", modeHtmlUrl);

import modeYamlUrl from "ace-builds/src-noconflict/mode-yaml?url";
ace.config.setModuleUrl("ace/mode/yaml", modeYamlUrl);

import themeGithubUrl from "ace-builds/src-noconflict/theme-github?url";
ace.config.setModuleUrl("ace/theme/github", themeGithubUrl);

import themeChromeUrl from "ace-builds/src-noconflict/theme-chrome?url";
ace.config.setModuleUrl("ace/theme/chrome", themeChromeUrl);

import themeMonokaiUrl from "ace-builds/src-noconflict/theme-monokai?url";
ace.config.setModuleUrl("ace/theme/monokai", themeMonokaiUrl);

import themetwilightUrl from "ace-builds/src-noconflict/theme-twilight?url";
ace.config.setModuleUrl("ace/theme/twilight", themetwilightUrl); //

import themeEclipseUrl from "ace-builds/src-noconflict/theme-eclipse?url";
ace.config.setModuleUrl("ace/theme/eclipse", themeEclipseUrl);

import themeTextmateUrl from "ace-builds/src-noconflict/theme-textmate?url";
ace.config.setModuleUrl("ace/theme/textmate", themeTextmateUrl);

import workerBaseUrl from "ace-builds/src-noconflict/worker-base?url";
ace.config.setModuleUrl("ace/mode/base", workerBaseUrl);

import workerJsonUrl from "ace-builds/src-noconflict/worker-json?url";
ace.config.setModuleUrl("ace/mode/json_worker", workerJsonUrl);

import workerJavascriptUrl from "ace-builds/src-noconflict/worker-javascript?url";
ace.config.setModuleUrl("ace/mode/javascript_worker", workerJavascriptUrl);

import workerHtmlUrl from "ace-builds/src-noconflict/worker-html?url";
ace.config.setModuleUrl("ace/mode/html_worker", workerHtmlUrl);

import workerYamlUrl from "ace-builds/src-noconflict/worker-yaml?url";
ace.config.setModuleUrl("ace/mode/yaml_worker", workerYamlUrl);

import snippetsHtmlUrl from "ace-builds/src-noconflict/snippets/html?url";
ace.config.setModuleUrl("ace/snippets/html", snippetsHtmlUrl);

import snippetsJsUrl from "ace-builds/src-noconflict/snippets/javascript?url";
ace.config.setModuleUrl("ace/snippets/javascript", snippetsJsUrl);

import snippetsYamlUrl from "ace-builds/src-noconflict/snippets/yaml?url";
ace.config.setModuleUrl("ace/snippets/javascript", snippetsYamlUrl);

import snippetsJsonUrl from "ace-builds/src-noconflict/snippets/json?url";
ace.config.setModuleUrl("ace/snippets/json", snippetsJsonUrl);

/////
import snippetsJavaUrl from "ace-builds/src-noconflict/snippets/java?url";
ace.config.setModuleUrl("ace/snippets/java", snippetsJavaUrl);

import snippetsC_cppUrl from "ace-builds/src-noconflict/snippets/c_cpp?url";
ace.config.setModuleUrl("ace/snippets/c_cpp", snippetsC_cppUrl);

import "ace-builds/src-noconflict/ext-language_tools";
ace.require("ace/ext/language_tools");
