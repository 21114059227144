import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import UserLayout from "@/layout/UserLayout.vue";
import CompeletionView from "@/view/competitions/CompeletionView.vue";
import CourceVIew from "@/view/cources/CourceView.vue";
import DiscussionView from "@/view/DiscussionView.vue";
import HomeView from "@/view/HomeView.vue";
import UserRegisterView from "@/view/user/UserRegisterView.vue";
import CompeletionContentView from "@/view/competitions/CompeletionContentView.vue";
import PostView from "@/view/posts/PostView.vue";
import PostContent from "@/view/posts/PostContent.vue";
import useUserStore from "@/store/user";
import { ACCESS_ENUM } from "@/access/access";
import checkAccess from "@/access/check";
import ProblemView from "@/view/problems/ProblemView.vue";
import ProblemMath408Content from "@/view/problems/math/ProblemMath408Content.vue";
import ProblemAlgorithmView from "@/view/problems/algorithm/ProblemAlgorithmView.vue";
import MarkdownEditorView from "@/view/problems/algorithm/AceEditorView.vue";
import PostProblemAnswerAdd from "@/view/posts/PostProblemAnswerAdd.vue";
import PostProblemSolutionAdd from "@/view/posts/PostProblemSolutionAdd.vue";
import CourceContentView from "@/view/cources/CourceContentView.vue";
import LoginView from "@/view/user/LoginView.vue";
import NotFoundView from "@/view/components/NotFoundView.vue";
import CompetitionMath408UserAdd from "@/view/competitions/CompetitionMath408UserAdd.vue";
import CompetitionAlgorithmInfoUserAdd from "@/view/competitions/Algorithm/CompetitionAdd/CompetitionAlgorithmInfoUserAdd.vue";
import CompetitionAlgorithmSelectUserAdd from "@/view/competitions/Algorithm/CompetitionAdd/CompetitionAlgorithmSelectUserAdd.vue";
import CompetitionAlgorithmProblemUserAdd from "@/view/competitions/Algorithm/CompetitionAdd/CompetitionAlgorithmProblemUserAdd.vue";
import CompetitionAlgorithmSuccessUserAdd from "@/view/competitions/Algorithm/CompetitionAdd/CompetitionAlgorithmSuccessUserAdd.vue";
import ProblemAlgorithmRecordsContent from "@/view/problems/algorithm/ProblemAlgorithmRecordsContent.vue";
import WebChatView from "@/view/WebChat/WebChatView.vue";
import AuthorizeRedirectView from "@/view/components/AuthorizeRedirectView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "首页",
    meta: {
      access: "0",
    },
    redirect: "/home",
  },
  {
    path: "/home",
    name: "主页",
    meta: {
      access: "0",
    },
    component: HomeView,
  },
  {
    path: "/user",
    name: "用户",
    children: [
      {
        path: "login",
        name: "用户登录",
        component: LoginView,
        meta: {
          access: "0",
          title: "登录",
        },
      },
      {
        path: "register",
        name: "用户注册",
        component: UserRegisterView,
        meta: {
          access: "0",
        },
      },
      {
        path: "",
        name: "用户中心",
        component: UserLayout,
        meta: {
          access: "1",
          title: "个人中心",
        },
      },
    ],
  },
  {
    path: "/posts",
    name: "",
    children: [
      {
        path: ":post_id",
        name: "帖子内容",
        meta: {
          access: "0",
          title: "原文",
        },
        component: PostContent,
      },
      {
        path: ":problem_id/answer/add",
        name: "添加答疑帖子",
        meta: {
          access: "1",
        },
        component: PostProblemAnswerAdd,
      },
      {
        path: ":problem_id/solution/add",
        name: "添加题解帖子",
        meta: {
          access: "1",
        },
        component: PostProblemSolutionAdd,
      },
      {
        path: "",
        name: "帖子",
        meta: {
          access: "0",
        },
        component: PostView,
      },
    ],
  },
  {
    path: "/competition/user/add",
    name: "竞赛信息",
    children: [
      {
        path: "1",
        name: "用户创建编程比赛",
        children: [
          {
            path: "info",
            name: "设置比赛基本信息",
            meta: {
              access: "1",
              title: "1.比赛设置",
            },
            component: CompetitionAlgorithmInfoUserAdd,
          },
          {
            path: "select",
            name: "设置比赛题目列表",
            meta: {
              access: "1",
              title: "2.题目选择",
            },
            component: CompetitionAlgorithmSelectUserAdd,
          },
          {
            path: "show",
            name: "修改题目名称信息还有序号信息",
            meta: {
              access: "1",
              title: "3.题目预览",
            },
            component: CompetitionAlgorithmProblemUserAdd,
          },
          {
            path: "success",
            name: "创界比赛次成功界面",
            meta: {
              access: "1",
              title: "4.比赛创建成功",
            },
            component: CompetitionAlgorithmSuccessUserAdd,
          },
        ],
        meta: {
          access: "1",
        },
      },
      {
        path: "2",
        name: "用户创建数学比赛",
        meta: {
          access: "1",
        },
        component: CompetitionMath408UserAdd,
      },
      {
        path: "3",
        name: "用户创建408比赛",
        meta: {
          access: "1",
        },
        component: CompetitionMath408UserAdd,
      },
    ],
    meta: {
      access: "1",
    },
  },
  {
    path: "/competition",
    name: "竞赛",
    children: [
      {
        path: ":competition_id",
        name: "竞赛编号",
        children: [
          {
            path: "records/:record_id",
            name: "提交记录",
            meta: {
              access: "1",
            },
            component: ProblemAlgorithmRecordsContent,
          },
          {
            path: "problem/:problem_index",
            name: "题目信息",
            meta: {
              access: "1",
              title: "竞赛",
            },
            component: ProblemAlgorithmView,
          },
          {
            path: "",
            name: "",
            meta: {
              access: "0",
              title: "竞赛",
            },
            component: CompeletionContentView,
          },
        ],
        meta: {
          access: "0",
        },
      },
      {
        path: "",
        name: "比赛列表",
        meta: {
          access: "0",
          title: "竞赛",
        },
        component: CompeletionView,
      },
    ],
  },
  {
    path: "/authorize/redirect",
    name: "用户授权登录",
    meta: {
      title: "授权",
    },
    component: AuthorizeRedirectView,
  },
  {
    path: "/study",
    children: [
      {
        path: ":plan_id",
        name: "学习计划规划",
        meta: {
          title: "学习计划",
        },
        component: CourceContentView,
      },
      {
        path: "",
        name: "学习计划主页",
        meta: {
          title: "学习计划",
        },
        component: CourceVIew,
      },
    ],
    meta: {
      access: "1",
    },
  },
  {
    path: "/discussion",
    name: "讨论",
    component: DiscussionView,
    meta: {
      access: "1",
      title: "讨论",
    },
  },
  {
    path: "/problems",
    name: "题库",
    children: [
      {
        path: "algorithm/:problem_id",
        name: "algorithm题目展示",
        meta: {
          access: "0",
          title: "题库",
        },
        component: ProblemAlgorithmView,
      },
      {
        path: "math408/:problem_id",
        name: "math和408题目展示",
        meta: {
          access: "0",
          title: "题库",
        },
        component: ProblemMath408Content,
      },
      {
        path: ":problem_id/records/:submission_id",
        name: "提交记录具体情况展示页面",
        meta: {
          access: "1",
        },
        component: ProblemAlgorithmRecordsContent,
      },
      {
        path: "",
        name: "题库展示页面",
        meta: {
          access: "0",
          title: "题库",
        },
        component: ProblemView,
      },
    ],
  },
  {
    path: "/markdown",
    name: "1",
    component: MarkdownEditorView,
  },
  {
    path: "/webChat",
    name: "websocket",
    meta: {
      title: "聊天室",
    },
    component: WebChatView,
  },
  {
    path: "/ip/lock",
    name: "IP锁定页面",
    component: NotFoundView,
  },
  {
    path: "/404",
    name: "404",
    meta: {
      title: "找不到页面",
    },
    component: NotFoundView,
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  window.document.title =
    to.meta.title === undefined ? "ByteOJ" : to.meta.title + " - ByteOJ";
  const userStore = useUserStore();
  let loginUser = userStore.loginUser;
  await userStore.getUserLocationInfo();

  // 如果之前没有登录，自动登录
  if (String(loginUser.role) == "0") {
    // 加await是为了获取用户登录态之后再执行代码

    await userStore.getLoginUser();
    loginUser = userStore.loginUser;
  }
  const needAccess: string =
    (to.meta?.access as string) ?? ACCESS_ENUM.NOT_LOGIN;
  // console.log(needAccess, loginUser.role);
  // 要跳转的页面需要登录
  if (needAccess != ACCESS_ENUM.NOT_LOGIN) {
    // 如果没有登录，跳转到登录页面
    if (!loginUser.role || String(loginUser.role) === ACCESS_ENUM.NOT_LOGIN) {
      next("/user/login");
    }
    // 如果已经登录了，但是权限不足，那么跳转到无权限的页面
    else if (!checkAccess(loginUser, needAccess)) {
      next("/home");
    }
    // 如果已经登录并且有权限，继续导航
    else {
      next();
    }
  } else if (String(loginUser.role) !== ACCESS_ENUM.BAN) {
    next();
  } else {
    next("/404");
  }
});

export default router;
