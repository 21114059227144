import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mx-auto" }
const _hoisted_2 = { class: "mx-auto" }
const _hoisted_3 = { class: "pt-20 pb-32 mx-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavbarView = _resolveComponent("NavbarView")!
  const _component_BottomView = _resolveComponent("BottomView")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_NavbarView, {
        class: "fixed",
        style: {"z-index":"9999"}
      })
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_BottomView, {
        class: "fixed w-full",
        style: {"z-index":"9999","bottom":"0"}
      })
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_view)
      ])
    ])
  ], 64))
}